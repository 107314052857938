import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import React from "react";
import {
  PARTY_BQ,
  PARTY_CONSERVATIVE,
  PARTY_GREEN,
  PARTY_LIBERAL,
  PARTY_NDP,
} from "../../utils";
import SeatMap from "../SeatsChart/seatschart";

export interface ElectionResultsProps {
  electoralSystem: string;
  electionYear: string;
  description: React.ReactNode;
  data: Record<string, PartyInfo>;
  link: string;
}

export interface PartyInfo {
  [seats: string]: number;
  percentage: number;
}

export default function ElectionResults(props: ElectionResultsProps) {
  return (
    <Box boxShadow={2} p={2}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">
            <Link href={props.link} target="_blank" rel="noopener">
              {props.electoralSystem}
            </Link>
          </Typography>
        </Grid>
        {props.description ? (
          <Grid item xs={12}>
            <Typography variant="body2">{props.description}</Typography>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {props.data ? (
            <SeatMap
              seatsLiberal={props.data[PARTY_LIBERAL].seats}
              seatsConservative={props.data[PARTY_CONSERVATIVE].seats}
              seatsNDP={props.data[PARTY_NDP].seats}
              seatsGreen={props.data[PARTY_GREEN].seats}
              seatsBQ={props.data[PARTY_BQ].seats}
            />
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
