export const data: Record<string, any> = {
  "2019": {
    Liberal: {
      seats: 157,
      percentage: 0.3312,
    },
    Conservative: {
      seats: 121,
      percentage: 0.3434,
    },
    NDP: {
      seats: 24,
      percentage: 0.1598,
    },
    Green: {
      seats: 3,
      percentage: 0.0655,
    },
    "Bloc Québécois": {
      seats: 32,
      percentage: 0.0763,
    },
  },
  "2015": {
    Liberal: {
      seats: 184,
      percentage: 0.3947,
    },
    Conservative: {
      seats: 99,
      percentage: 0.3189,
    },
    NDP: {
      seats: 44,
      percentage: 0.1971,
    },
    Green: {
      seats: 1,
      percentage: 0.0345,
    },
    "Bloc Québécois": {
      seats: 10,
      percentage: 0.0466,
    },
  },
  "2011": {
    Liberal: {
      seats: 34,
      percentage: 0.1891,
    },
    Conservative: {
      seats: 166,
      percentage: 0.3962,
    },
    NDP: {
      seats: 103,
      percentage: 0.3063,
    },
    Green: {
      seats: 1,
      percentage: 0.0391,
    },
    "Bloc Québécois": {
      seats: 4,
      percentage: 0.0604,
    },
  },
  "2008": {
    Liberal: {
      seats: 77,
      percentage: 0.2626,
    },
    Conservative: {
      seats: 143,
      percentage: 0.3765,
    },
    NDP: {
      seats: 37,
      percentage: 0.1818,
    },
    Green: {
      seats: 0,
      percentage: 0.0678,
    },
    "Bloc Québécois": {
      seats: 49,
      percentage: 0.0998,
    },
  },
  "2006": {
    Liberal: {
      seats: 103,
      percentage: 0.3023,
    },
    Conservative: {
      seats: 124,
      percentage: 0.3627,
    },
    NDP: {
      seats: 29,
      percentage: 0.1748,
    },
    Green: {
      seats: 0,
      percentage: 0,
    },
    "Bloc Québécois": {
      seats: 51,
      percentage: 0.1048,
    },
  },
  "2004": {
    Liberal: {
      seats: 135,
      percentage: 0.3673,
    },
    Conservative: {
      seats: 99,
      percentage: 0.2963,
    },
    NDP: {
      seats: 19,
      percentage: 0.1568,
    },
    Green: {
      seats: 0,
      percentage: 0,
    },
    "Bloc Québécois": {
      seats: 54,
      percentage: 0.1239,
    },
  },
};
