import { AppBar, Toolbar, Typography } from "@material-ui/core";
import React from "react";

export default function Header() {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6">
          How would the Canadian House of Commons look under various electoral
          systems?
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
