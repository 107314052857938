import ReactApexCharts from "react-apexcharts";
import { PoliticalParties, PoliticalPartyColors } from "../../utils";

export interface SeatsChartProps {
  [seatsLiberal: string]: number;
  seatsConservative: number;
  seatsNDP: number;
  seatsGreen: number;
  seatsBQ: number;
}

export const DEFAULT_CHART_HEIGHT = 300;

export default function SeatsChart(props: SeatsChartProps) {
  const series = [
    {
      data: Object.keys(props)
        .map((key) => {
          return props[key];
        })
        .filter((value) => value !== undefined),
    },
  ];

  const options = {
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    colors: Object.values(PoliticalPartyColors),
    xaxis: {
      categories: PoliticalParties,
      min: 0,
      max: getChartMaximum(Object.values(props)),
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        horizontal: true,
        distributed: true,
      },
    },
    title: {
      text: "Seats per party",
      align: "center",
    },
  };

  return (
    <div id="chart" style={{ marginTop: 10 }}>
      <ReactApexCharts
        options={options}
        series={series}
        type="bar"
        height={DEFAULT_CHART_HEIGHT}
      />
    </div>
  );
}

export function getChartMaximum(nums: number[]) {
  const AT_LEAST = 160;
  const BUFFER = 10;
  const maxNum = Math.max(...nums);
  if (maxNum < AT_LEAST) {
    return AT_LEAST;
  }
  return maxNum + BUFFER;
}
