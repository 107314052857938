import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import "./App.css";
import Header from "./components/Header/header";
import ElectionResults from "./components/ElectionResults/electionResults";
import { ElectoralSystems } from "./systemDefinitions";
import { data } from "./data";
import { didPopularVoteWin } from "./utils";

function App() {
  const [electionYear, setElectionYear] = useState("2019");

  const { popularVoteWon, mostSeats, mostVotes } = didPopularVoteWin(
    data[electionYear]
  );

  return (
    <div className="App">
      <Container>
        <Grid container spacing={2}>
          <Grid key="header" item xs={12}>
            <Header />
          </Grid>
          <Grid key="marketing-message" item xs={12}>
            <Typography>
              Select an election year to see how many seats each party would
              receive under each electoral system.
            </Typography>
          </Grid>
          <Grid key="year-input" item xs={12}>
            <FormControl style={{ minWidth: 120 }}>
              <InputLabel id="year-select-label">Election year</InputLabel>
              <Select
                labelId="year-select-label"
                id="year-select"
                value={electionYear}
                onChange={(e) => {
                  setElectionYear(e.target.value as string);
                }}
              >
                {Object.keys(data).map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {popularVoteWon ? (
              <Alert severity="info">
                In the {electionYear} election, the party that received the most
                votes ({mostVotes}) won the election.
              </Alert>
            ) : (
              <Alert severity="warning">
                In the {electionYear} election, the party that received the most
                votes ({mostVotes}) lost the election.
              </Alert>
            )}
          </Grid>

          <Grid key="election-results" item xs={12}>
            {Object.keys(ElectoralSystems).map((system) => {
              return (
                <ElectionResults
                  key={`election-results-${ElectoralSystems[system].name}`}
                  electoralSystem={ElectoralSystems[system].name}
                  electionYear={electionYear}
                  description={ElectoralSystems[system].description}
                  link={ElectoralSystems[system].link}
                  data={ElectoralSystems[system].dataTransformer(
                    data[electionYear]
                  )}
                />
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default App;
