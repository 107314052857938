import { PartyInfo } from "./components/ElectionResults/electionResults";

export const ElectionYears = ["2019"];

export const PARTY_LIBERAL = "Liberal";
export const PARTY_CONSERVATIVE = "Conservative";
export const PARTY_NDP = "NDP";
export const PARTY_BQ = "Bloc Québécois";
export const PARTY_GREEN = "Green";
export const PoliticalParties = [
  PARTY_LIBERAL,
  PARTY_CONSERVATIVE,
  PARTY_NDP,
  PARTY_GREEN,
  PARTY_BQ,
];

export const PoliticalPartyColors: Record<string, string> = {
  [PARTY_LIBERAL]: "#D71920",
  [PARTY_CONSERVATIVE]: "#1A4782",
  [PARTY_NDP]: "#F37021",
  [PARTY_GREEN]: "#3D9B35",
  [PARTY_BQ]: "#33B2CC",
};

export const TOTAL_SEATS = 338;

export function didPopularVoteWin(data: Record<string, PartyInfo>) {
  const mostSeats = Object.keys(data).reduce((a, b) =>
    data[a].seats > data[b].seats ? a : b
  );
  const mostVotes = Object.keys(data).reduce((a, b) =>
    data[a].percentage > data[b].percentage ? a : b
  );
  return {
    popularVoteWon: mostSeats === mostVotes,
    mostSeats,
    mostVotes,
  };
}
