import { TOTAL_SEATS } from "./utils";

export function mapDataFirstPastThePost(data: any) {
  return data;
}

export function mapDataProportionalRepresenration(data: any): any {
  return Object.keys(data).reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: {
        seats: Math.round(data[curr].percentage * TOTAL_SEATS),
      },
    };
  }, {});
}

export const ElectoralSystems: Record<string, any> = {
  FPTP: {
    name: "First past the post",
    description: (
      <span>
        First past the post is the system currently being used in Canada. In
        each riding, the candidate that receives the most votes is given the
        corresponding seat in the House of Commons.
      </span>
    ),
    link: "https://en.wikipedia.org/wiki/First-past-the-post_voting",
    dataTransformer: mapDataFirstPastThePost,
  },
  ProportionalRepresentation: {
    name: "Proportional representation",
    description: (
      <span>
        With proportional representation, seats in the House of Commons would be
        allocated to parties based on what percentage of the popular vote they
        receive.
      </span>
    ),
    link: "https://en.wikipedia.org/wiki/Proportional_representation",
    dataTransformer: mapDataProportionalRepresenration,
  },
};
